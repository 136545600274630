<template>
  <v-row>
    <v-btn
      v-if="!agendar"
      @click="agendar = true"
      id="addItem"
      color="accent"
      fab
      fixed
      bottom
      right
      style="margin-bottom: 58px !important"
    >
      <v-icon>fas fa-plus</v-icon>
    </v-btn>
    <template v-if="!agendar">
      <template v-for="(agrupador, aindex) in agrupadores">
        <template v-if="!onlyMonths || (onlyMonths && agrupador.month)">
          <v-col
            cols="12"
            :key="'agrupador_' + '_' + aindex"
            class="text-center"
          >
            <v-card class="subtitle-1 primary white--text elevation-1">
              {{ agrupador.descricao }}
            </v-card>
          </v-col>
          <v-col cols="12" :key="'carteira_' + '_' + aindex">
            <v-row no-gutters>
              <v-col cols="12" class="body-2">
                <v-list
                  v-if="itens.length > 0"
                  :two-line="true"
                  color="transparent"
                  class="custom"
                >
                  <template v-for="(item, iitem) in itens">
                    <v-list-item :key="'list-item' + iitem">
                      <v-list-item-avatar>
                        <v-icon small>far fa-circle</v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>{{
                          item.descricao
                        }}</v-list-item-title>
                        <v-list-item-subtitle
                          class="caption"
                          v-if="item.info.tipo"
                          >{{ item.info.tipo }}</v-list-item-subtitle
                        >
                        <v-list-item-subtitle class v-if="item.info.medico">
                          {{ item.info.medico }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle class v-if="item.info.local">
                          {{ item.info.local }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action class="caption mr-2">
                        <v-list-item-action-text class="purple--text">
                          <span>{{ item.nomefun }}</span>
                        </v-list-item-action-text>

                        <v-list-item-action-text v-if="item.status.ok">
                          <template v-if="item.info.dtexecutada">
                            Realizado em
                            <br />
                            {{ item.info.dtexecutada }}
                          </template>
                        </v-list-item-action-text>
                        <v-list-item-action-text v-else>
                          <template v-if="item.info.dtprevista">
                            <template v-if="item.status.sigla == 'FU'"
                              >Agendado para</template
                            >
                            <template v-else>Previsto para</template>
                            <br />
                            <span class="black--text font-weight-bold">{{
                              item.info.dtprevista
                            }}</span>
                          </template>
                        </v-list-item-action-text>
                      </v-list-item-action>
                    </v-list-item>
                    <v-divider
                      v-if="itens.length != iitem + 1"
                      :key="'divider' + '_' + iitem"
                    ></v-divider>
                  </template>
                </v-list>
                <div v-else class="grey--text text-center">
                  Não possui itens
                </div>
              </v-col>
            </v-row>
          </v-col>
        </template>
      </template>
    </template>
    <v-col class="ml-10" cols="10" v-else>
      <v-row>
        <v-col cols="12" class="caption text-right font-weight-bold py-0">
          <template>Massagem</template>
        </v-col>
        <v-col cols="12" class="caption text-right font-weight-bold py-0">
          <template>IVSON VITAL</template>
        </v-col>

        <v-col cols="12" class="isRelative">
          <v-menu
            :close-on-content-click="true"
            transition="scale-transition"
            v-model="this.quando"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="computedDateFormatted"
                label="Data"
                prepend-inner-icon="fas fa-calendar-alt"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker no-title color="primary"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" class="isRelative">
          <v-select :items="tiposagendamento" label="Tipo"></v-select>
        </v-col>
        <v-col cols="12" class="mt-5">
          <v-btn outlined block color="primary">{{
            newItem ? "Salvar" : "Atualizar"
          }}</v-btn>
        </v-col>
        <v-col cols="12" class="mt-5">
          <v-btn outlined block color="primary" @click="goCancelar()"
            >Cancelar</v-btn
          >
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
export default {
  data() {
    return {
      onlyMonths: false,
      agendar: false,
      newItem: true,
      quando: "",
      itens: [
        {
          descricao: "Massagem",
          nomefun: "IVSON VITAL",
          info: {
            tipo: "Relaxamento",
            medico: "Dr. Ataíde",
            local: "",
            dtexecutada: "10/10/2020",
            dtprevista: "10/10/2020",
          },
          status: {
            ok: false,
            sigla: "FU",
          },
        },
      ],
      tiposagendamento: [
        { value: "massagem", text: "massagem" },
        { value: "academia", text: "academia" }],
      agrupadores: [
        {
          descricao: "Agendamentos Marcados",
          status: ["NF", "FU"],
          month: false,
          crescente: true,
        },
      ],
    };
  },
  methods: {
    goCancelar() {
      this.agendar = false;
      this.newItem = false;
    },
  },
  computed: {
     computedDateFormatted() {
      return this.$util.vueFormatDateToBR(this.quando);
    }
  }
};
</script>

<style>
/*  */
#tabsContainer .v-tabs-bar .v-tab--active {
  color: var(--v-black);
}

#tabsContainer .v-tab {
  letter-spacing: inherit;
}

.v-slide-group__prev--disabled {
  display: none !important;
}
</style>
